
//== Font loading
@mixin fontface($name, $file, $weight: normal, $style: normal) {
    @font-face {
        font-weight: $weight;
        font-family: '#{$name}';
        font-style: $style;
        font-display: swap;
        src: url('resources/fonts/#{$file}.woff2') format('woff2'), url('resources/fonts/#{$file}.woff') format('woff');
    }
}

// VB brand fonts
@include fontface(titleFont, 'BrusselsSerif/BrusselsSerif-Regular', normal, normal);
@include fontface(defaultFont, 'BrusselsSerif/BrusselsSerif-Regular', normal, normal);

@include fontface(defaultFont, 'Inter/Inter-Thin', 100, normal);
@include fontface(defaultFont, 'Inter/Inter-ExtraLight', 200, normal);
@include fontface(defaultFont, 'Inter/Inter-Light', 300, normal);
@include fontface(defaultFont, 'Inter/Inter-Regular', 400, normal);
@include fontface(defaultFont, 'Inter/Inter-Medium', 500, normal);
@include fontface(defaultFont, 'Inter/Inter-SemiBold', 600, normal);
@include fontface(defaultFont, 'Inter/Inter-Bold', 700, normal);
@include fontface(defaultFont, 'Inter/Inter-ExtraBold', 800, normal);
@include fontface(defaultFont, 'Inter/Inter-Black', 900, normal);
