.cta-banner-container__image {
  @media (min-width: size(large)) {
    width: 45%;
  }

  margin: auto;

  .image-container {
    top: 0;
  }
}
