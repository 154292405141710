//== Color

// Theme colors
$color-theme: #A7C116;

// Neutral colors
$color-white: #fff;
$color-tint-2: #F5F5F5;
$color-tint-3: #E5E5E5;
$color-tint-4: #CBCBCB;
$color-tint-5: #9B9B9B;
$color-tint-6: #757575;
$color-tint-7: #292929;
$color-tint-8: #111111;
$color-black: $color-tint-8;

// Alert colors
$color-success: #00AA44;
$color-warning: #FF732E;
$color-error: #CC0000;
