
//== Font
$font-size: 16px;
$font-height: 20px;

// BORDER RADIUS
$border-radius: 26px;

//== OLD Colors
$color-accent: #2954ff;
$color-accent-lighter: #436eff;
$color-accent-darker: #103be6;
$color-shade-1: #eceff1;
$color-shade-2: #cfd8dc;
$color-shade-3: #607d8b;
$color-shade-4: #455a64;

//== Color

// Neutral colors
$color-white: #fff;
$color-tint-2: #F5F5F5;
$color-tint-3: #E5E5E5;
$color-tint-4: #CBCBCB;
$color-tint-5: #9B9B9B;
$color-tint-6: #757575;
$color-tint-7: #292929;
$color-tint-8: #111111;
$color-tint-9: #ECE9E5;
$color-black: $color-tint-8;

// Alert colors
$color-success: #00AA44;
$color-warning: #FF732E;
$color-error: #CC0000;

//== Spaces & gutters
$gutter: 24px;
$margin: 57px;
$margin-mobile: 16px;

// SIZES
// Grid & Container sizes
$grid-huge: 1440px;
$grid-large: 1326px;
$grid-medium: 1100px;
$grid-small: 878px;
$max-width-desktop: 1920px;

// Breakpoints
$sizes: (
        tiny: 330px,
        small: 768px,
        medium: 1025px,
        large: 1200px,
        huge: 1440px,
        gigantic: 1700px
);

@function size($size) {
  @return map-get($sizes, $size);
}
