.cmp-download {
  font-family: defaultFont, sans-serif;
  @include rem(font-size, 17);
  padding: 11px 24px;
  height: 50px;
  font-weight: 500;
  border: 1px solid $color-black;
  border-radius: $border-radius;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, opacity 0.2s;

  background: transparent;
  color: $color-black;

  @media (min-width: size(medium)) {
    &:hover {
      background: $color-tint-7;
      border-color: $color-tint-7;
      color: $color-white;

      .cmp-download__action-text,
      .cmp-download__properties,
      .cmp-download__title-link {
        color: $color-white;
      }

      &:after {
        background-color: $color-white;
      }
    }
  }
}

.cmp-download__title {
  margin-top: 3px;
  font-family: defaultFont, sans-serif;
}

.cmp-download__title-link {
  color: $color-black;
  font-weight: 400;
  font-size: 1.125rem;
  text-decoration: none;
  margin: 0 5px;
  display: flex;

  &:after {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: -25%;
    content: '|';
    color: $color-shade-3;
  }
}

.cmp-download__properties {
  display: flex;
  margin-top: 23px;
  color: $color-shade-3;
  font-weight: 400;
  font-size: 0.625em;

  & div {
    float: left;
  }
}

.cmp-download__property {
}

.cmp-download__property-label {
  display: none;
}

.cmp-download__property-content {
  margin: 0 0.625rem 1.0625rem 0;
}

.cmp-download__property--filename {
  display: none;
}

.cmp-download__property--size {
  order: 2;
}

.cmp-download__property--format {
  order: 1;
  text-transform: uppercase;
}

.cmp-download__action {
  order: 0;
  text-decoration: none;

  &:disabled,
  .is-disabled {
    background-color: $color-tint-2;
    border-color: $color-tint-2;
    color: $color-tint-4;
    cursor: not-allowed;
  }

  @media (min-width: size(medium)) {
    &:hover {
      background: $color-tint-7;
      border-color: $color-tint-7;
      color: $color-white;
    }
  }
}

.cmp-download {
  &:after {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 0.625em;
    vertical-align: -25%;
    background-color: $color-tint-7;
    content: '';
    mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojZmZmO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01NjUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTY1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNNC4yOTMsMTMuMTIyYTEsMSwwLDAsMSwwLTEuNDE0TDguMTcyLDcuODI5SC0yLjE3MWExLDEsMCwwLDEtMS0xLDEsMSwwLDAsMSwxLTFIOC4zMTNsLTQuMDItNC4wMkExLDEsMCwwLDEsNS43MDcuMzk0TDExLjM0LDYuMDI2bC4wMjQuMDI0YTEsMSwwLDAsMSwwLDEuNDE1TDUuNzA3LDEzLjEyMmExLDEsMCwwLDEtMS40MTQsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDU4My44MjkgODguMTcxKSByb3RhdGUoOTApIi8+PC9nPjwvc3ZnPg==');
    mask-size: cover;
  }
}
