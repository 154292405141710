.cmp-navigation-side-bar__nav {
  background: $color-tint-9;

  height: calc(100vh - 165px);

  *::-webkit-scrollbar-track {
    background-color: $color-tint-9;
  }
}

.cmp-navigation-block {
  .cmp-media-text--image-right {
    .cmp-media-text__content {
      padding: 90px 50px 90px 0;
    }
  }

  .cmp-media-text--image-left {
    .cmp-media-text__content {
      padding: 90px 0 90px 50px;
    }
  }

  .cmp-block__columns-wrapper.u-max-width--small,
  .cmp-block__columns-wrapper.u-max-width--medium,
  .cmp-block__columns-wrapper.u-max-width--large {
    @media (min-width: size(large)) {
      .content {
        .cmp-block__content.u-max-width--small,
        .cmp-block__content.u-max-width--medium {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  @media (min-width: size(large)) {
    .cmp-block__columns-wrapper.u-max-width--large {
      margin: auto;

      .cmp-block__columns-wrapper__column {
        &:first-child {
          .cmp-block__content {
            padding-right: 30px;
            padding-left: 0;
          }
        }

        &:last-child {
          .cmp-block__content {
            padding-left: 30px;
            padding-right: 0;
          }
        }
      }
    }
  }
}

// With the background color, the side nav bar is covering the footer in the editor cause the js not properly loaded
.cq-Editable-dom--container .cmp-navigation-side-bar__nav {
  height: auto;
}
