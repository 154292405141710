.cmp-form {
  .cmp-input-field__placeholder {
    z-index: 0;
    color: $color-black;
  }

  .cmp-input-field__input:focus + .cmp-input-field__placeholder,
  .cmp-input-field__textarea:focus + .cmp-input-field__placeholder {
    color: $color-black;
  }

  .cmp-input-field__input:focus-visible,
  .cmp-input-field__select:focus-visible,
  .cmp-input-field__textarea:focus-visible {
    border-color: $color-black;
  }

  .cmp-checkbox input[type='checkbox']:checked,
  .cmp-radio input[type='checkbox']:checked {
    background: $color-black;
    border: 1px solid $color-black;
  }

  textarea,
  input[type='text'],
  input[type='email'] {
    font-family: defaultFont, sans-serif;
  }

  .cmp-input-field__input--filled + .cmp-input-field__placeholder,
  .cmp-input-field__input:focus + .cmp-input-field__placeholder,
  .cmp-input-field__textarea--filled:focus + .cmp-input-field__placeholder,
  .cmp-input-field__textarea--filled + .cmp-input-field__placeholder {
    top: 6px;
  }

  .cmp-select-field:after {
    z-index: 0;
  }
}
