.cmp-languagenavigation {
}

//.cmp-languagenavigation--countrycode-US
//.cmp-languagenavigation--langcode-en-US
.cmp-languagenavigation__group {
}

.cmp-languagenavigation__item {
}

.cmp-languagenavigation__item--active {
}

//.cmp-languagenavigation__item--level-0 {}
.cmp-languagenavigation__item-link {
}

.cmp-languagenavigation__item-title {
}

.cmp-dropdown .cmp-dropdown__title {
  font-family: defaultFont, sans-serif;
  color: $color-black;
}

.cmp-language-navigation__item-link {
  &--active {
    color: $color-black;
    font-weight: 700;
  }

  &:hover {
    color: $color-black;
    font-weight: 700;
  }
}
