//== Defaults

html,
body {
  margin: 0;
  color: $color-black;
  font-size: $font-size;
  font-family: defaultFont, sans-serif;
  line-height: $font-height;
  background: $color-tint-9;

  //needed for the navigation block. if this is removed it will disappear when it's z-index is set to -1
  //this is because we have a bg set (on VB site the bg is transparent)
  position: relative;
  z-index: -1;
}

.overflow-hidden {
  overflow: hidden;
}

a {
  color: $color-tint-6;
}

p {
  margin-block: 0.75rem;
  line-height: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
  font-family: titleFont, serif;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: 768px) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}

*::-webkit-scrollbar-track {
  background-color: $color-tint-9;
}

*::-webkit-scrollbar {
  background-color: $color-tint-9;
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: $color-tint-4;
  border-radius: 16px;
  border: 4px solid #ece9e5;
}
