.cmp-text {
  p {
    font-family: defaultFont, sans-serif;
  }

  & p a {
    font-family: defaultFont, sans-serif;
  }
  & blockquote {
    font-family: titleFont, serif;
    font-style: italic;
  }
}
.cmp-text__paragraph {
}
.cmp-text ol li,
.cmp-text p,
.cmp-text ul li,
div.coral-RichText ol li,
div.coral-RichText p,
div.coral-RichText ul li,
.text {
  font-family: defaultFont, sans-serif;
}

.cmp-text a,
div.coral-RichText a {
  color: $color-tint-6;
}

div.coral-RichText p b,
div.coral-RichText p strong,
div.coral-RichText ul li b,
div.coral-RichText ul li strong,
div.coral-RichText ol li b,
div.coral-RichText ol li strong,
.cmp-text p b,
.cmp-text p strong,
.cmp-text ul li b,
.cmp-text ul li strong,
.cmp-text ol li b,
.cmp-text ol li strong {
  font-weight: 700;
}
