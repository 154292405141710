
//== Variables

@import 'site/variables';
@import 'site/grid';

//== Site global

@import 'npm:normalize.css';
@import 'site/fonts';
@import 'site/colors';
@import 'site/base';
@import 'site/mixins/rem.scss';

//== Core components

@import 'src/components/text/_text.scss';

@import 'src/components/title/_title.scss';
@import 'src/components/title/_title_styles.scss';

@import 'src/components/image/_image.scss';
@import 'src/components/image/_image_caption.scss';
@import 'src/components/image/_image_standard.scss';

@import 'src/components/button/_button.scss';
@import 'src/components/button/_button_call_to_action.scss';
@import 'src/components/button/_button_primary.scss';
@import 'src/components/button/_button_primary_over_background.scss';
@import 'src/components/button/_button_quiet.scss';
@import 'src/components/button/_button_quiet_over_background.scss';

@import 'src/components/separator/_separator.scss';
@import 'src/components/separator/_separator_small.scss';
@import 'src/components/separator/_separator_standard.scss';

@import 'src/components/progressbar/_progressbar.scss';

@import 'src/components/teaser/_teaser.scss';
@import 'src/components/teaser/_teaser_dark.scss';
@import 'src/components/teaser/_teaser_text_at_bottom.scss';
@import 'src/components/teaser/_teaser_text_at_left.scss';
@import 'src/components/teaser/_teaser_text_at_right.scss';

@import 'src/components/download/_download.scss';

//== Lists and Navigation components

@import 'src/components/list/_list.scss';

@import 'src/components/navigation/_navigation.scss';

@import 'src/components/languagenavigation/_languagenavigation.scss';

@import 'src/components/breadcrumb/_breadcrumb.scss';
@import 'src/components/breadcrumb/_breadcrumb_standard.scss';

@import 'src/components/search/_search.scss';

//== Container components

@import 'src/components/container/_container.scss';
@import 'src/components/container/_container_content.scss';
@import 'src/components/container/_container_dark_theme.scss';
@import 'src/components/container/_container_main.scss';

@import 'src/components/carousel/_carousel.scss';

@import 'src/components/tabs/_tabs.scss';
@import 'src/components/tabs/_tabs_horizontal.scss';
@import 'src/components/tabs/_tabs_vertical.scss';

@import 'src/components/accordion/_accordion.scss';

//== Fragments (CF + XF) components

@import 'src/components/contentfragment/_contentfragment.scss';
@import 'src/components/contentfragmentlist/_contentfragmentlist.scss';

@import 'src/components/experiencefragment/_experiencefragment.scss';
@import 'src/components/experiencefragment/_experiencefragment_footer.scss';
@import 'src/components/experiencefragment/_experiencefragment_header.scss';

//== Form components

@import 'src/components/form/_form.scss';
@import 'src/components/form-button/_form-button.scss';
@import 'src/components/form-text/_form-text.scss';
@import 'src/components/form-options/_form-options.scss';

//== Embed components

@import 'src/components/embed/_embed.scss';

@import 'src/components/pdfviewer/_pdfviewer.scss';

@import 'src/components/socialmediasharing/_social_media_sharing.scss';

//== VB components
@import 'src/components/ctabanner/_ctabanner.scss';
@import 'src/components/infocard/_infocard.scss';
@import 'src/components/itinerary/_itinerary.scss';
@import 'src/components/eventdetail/_eventdetail.scss';
@import 'src/components/imagebanner/_imagebanner.scss';
@import 'src/components/partner/_partner.scss';
@import 'src/components/newslettersubscription/_newslettersubscription.scss';
@import 'src/components/navigationblock/_navigationblock.scss';
@import 'src/components/blockcontent/_blockcontent.scss';
@import 'src/components/documentlist/_documentlist.scss';
