.cmp-block {
  max-width: 100%;

  .cmp-block__content.u-max-width--large,
  .cmp-block__content.u-max-width--small {
    .cmp-image {
      img {
        @media (min-width: size(small)) {
          max-height: 100%;
        }
      }
    }
  }

  .cmp-block__columns-wrapper {
    .cmp-video-banner__element, .cmp-video-banner__teaser-image {
      position: relative;
    }
  }

  .cmp-block__columns-wrapper.u-max-width--large,
  .cmp-block__columns-wrapper.u-max-width--small,
  .cmp-block__columns-wrapper.u-max-width--medium {
    .cmp-image {
      img {
        max-height: 100%;

        @media (min-width: size(small)) {
          max-height: 492px;
        }
      }
    }
  }
}
